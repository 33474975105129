<template>
  <div class="m-3">
    <div class="mb-8">
      <v-alert v-if="!personalDataFilled" color="primary" class="white--text">
        Для оформления заявки вам необходимо указать данные паспорта в разделе "Персональные данные"
      </v-alert>
      <v-form v-model="valid">
        <v-row class="mb-4">
          <v-col cols="12" md="6">
            <v-text-field
                v-model="form.name"
                label="Контрагент"
                placeholder="Контрагент по договору проката:"
                required
                readonly
            >
            </v-text-field>

            <v-select
                v-model="activeCity"
                :items="getCityList"
                label="Выберите регион"
                prepend-icon="mdi-earth"
                item-text="name"
                item-value="id"
                required
            ></v-select>

            <v-select
                v-model="activeSite"
                :items="getSites"
                label="Выберите площадку"
                prepend-icon="mdi-earth"
                item-text="name"
                item-value="guid"
                required
            ></v-select>

            <v-textarea
                label="Площадка"
                v-model="siteInfo"
                no-resize
                placeholder="Прокатная площадка, адрес, телефон"
                rows="3"
                readonly
                required
            ></v-textarea>

            <vv-date-picker
                v-model="dates"
                mode="dateTime"
                is-range
                is24hr
                :min-date="minDate"
                gi
                :valid-hours="{ min: 9, max: 19 }"
                :minute-increment="10"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                        v-on="inputEvents.start"
                        :value="formatDate(dates.start)"
                        label="Начало"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                        v-on="inputEvents.end"
                        :value="formatDate(dates.end)"
                        label="Окончание"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </vv-date-picker>

            <v-row>
              <v-col cols="12" md="7">
                <v-select
                    v-model="form.model"
                    :items="carItems"
                    label="Марка автомобиля"
                    prepend-icon="mdi-car"
                    item-text="name"
                    item-value="guid"
                    required
                ></v-select>
              </v-col>

              <v-col cols="12" md="5">
                <v-text-field
                    v-model="rate"
                    label="Тариф"
                    :rules="[RequiredValidator]"
                    required
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="5">
                <v-text-field
                    v-model="daysCount"
                    label="Суток аренды"
                    placeholder="Количество суток аренды"
                    :rules="[RequiredValidator]"
                    type="number"
                    required
                    readonly
                ></v-text-field>
              </v-col>

              <v-col cols="5" offset="2">
                <v-text-field
                    v-model="totalForm"
                    label="Итого"
                    :rules="[RequiredValidator]"
                    placeholder=""
                    suffix="₽"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-textarea
                v-model="form.commentary"
                label="Дополнительные пожелания"
                no-resize
                rows="3"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
                clearable
                v-model="form.driver[0]"
                :items="drivers"
                label="Основной водитель"
                :rules="[DriverLicenseValidator(form.driver[0],drivers),RequiredValidator]"
                prepend-icon="mdi-account"
                item-text="name"
                item-value="id"
                required
            >
              <template #prepend-item>
                <v-list-item>
                  Для выбора водителей необходимо, чтобы были добавлены данные ВУ
                </v-list-item>

              </template>
            </v-autocomplete>

            <v-autocomplete
                clearable
                v-model="form.driver[1]"
                :items="drivers"
                :rules="[DriverLicenseValidator(form.driver[1],drivers)]"
                label="Дополнительный водитель 1"
                prepend-icon="mdi-account"
                item-text="name"
                item-value="id"
            ></v-autocomplete>

            <v-autocomplete
                clearable
                v-model="form.driver[2]"
                :rules="[DriverLicenseValidator(form.driver[2],drivers)]"
                v-if="form.driver[1]"
                :items="drivers"
                label="Дополнительный водитель 2"
                prepend-icon="mdi-account"
                item-text="name"
                item-value="id"
            ></v-autocomplete>

            <div class="d-flex align-center mb-4">
              <div class="me-2"><b>Маршрут:</b></div>
              <v-treeview
                  color="primary"
                  v-model="selectedDistricts"
                  selectable
                  :open="openedDistrict"
                  @update:open="onVTreeViewOpened"
                  :multiple-active="false"
                  :items="routeTree"
              ></v-treeview>
            </div>

            <div>
              <v-checkbox :rules="[RequiredValidator]">
                <template v-slot:label>
                  <div>
                    Данные документов и сканы контрагента, основного и дополнительного водителя (если он есть)
                    соответствуют указанным и действительным
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox :rules="[RequiredValidator]">
                <template v-slot:label>
                  <div>
                    С правилами проката ознакомлен (-на)
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-col>

        </v-row>

        <v-btn
            color="primary"
            :disabled="!valid || !personalDataFilled"
            :loading="loading"
            block
            @click="orderNew"
        >
          Подать заявку на прокат
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import _ from "lodash";
import moment from "@/plugins/moment";
import {DatePicker} from "v-calendar";
import {DistrictService} from "../services/districtService";
import {DriverLicenseValidator, RequiredValidator} from "../validation/Base";
import {formatFio} from "@/modules/client/formats/fio";

export default {
  name: "ClientOrder",
  components: {VvDatePicker: DatePicker},
  data: () => ({
    loading: false,
    site: {},
    openedDistrict: [],
    dates: {
      start: null,
      end: null,
    },
    districts: null,
    selectedDistricts: [],
    valid: false,
    minDate: moment().add(2, "hour").toISOString(),
    form: {
      type: "individual",
      name: "",
      startDate: "",
      endDate: "",
      site: null,
      commentary: "",
      route_info: "",
      model: null,
      days: 1,
      driver: {
        0: "",
        1: "",
        2: "",
      },
    },
  }),
  computed: {
    ...mapGetters("config", ["getSites", "getDate", "getActiveSite", "getCityList", "getActiveCity"]),
    ...mapGetters("user", ["user"]),
    ...mapGetters("car", ["getCurrentCar", "getCars","getFreeCars"]),
    ...mapGetters("drivers", ["getDrivers"]),
    personalDataFilled() {
      if (!this.user.client || !this.user.client.document || !this.user.client.document.series) {
        return false
      }

      if (!this.user.client.name || !this.user.client.surname || !this.user.client.lastname) {
        return false
      }

      return true
    },
    routeTree() {
      return (
          this.districts?.map((district) => {
            return {
              id: district.code,
              name: district.name,
              children: district.areas,
            };
          }) || []
      );
    },
    siteInfo() {
      let currentSite =
          _.filter(this.getSites, {
            guid: this.form.site,
          })[0] || null;

      if (!currentSite) {
        return "";
      }
      return (
          currentSite.name +
          (currentSite.address ? ", " + currentSite.address : "") +
          (currentSite.phone ? ", " + currentSite.phone : "")
      );
    },
    daysCount() {
      if (!this.dates) {
        return "";
      }

      let fromDate = moment(this.dates.start);
      let toDate = moment(this.dates.end);

      return toDate.diff(fromDate, "days");
    },
    rate() {
      const currentCar = _.find(this.carItems, {guid: this.form.model});

      if (!currentCar) {
        return "";
      }
      const rates = currentCar.rates;
      const currentRate = _.find(rates, (item) => {
        return (
            item.start_day <= this.daysCount &&
            (item.end_day >= this.daysCount || item.end_day === 0)
        );
      });
      return currentRate ? currentRate.summ : "";
    },
    totalForm() {
      if (this.rate && this.daysCount) {
        return this.rate * this.daysCount;
      } else {
        return "";
      }
    },
    carItems() {
      const freeGuids = _.map(this.getFreeCars,'guid')
      return (this.getCars || []).map(o=>{
        return {
          ...o,
          disabled: !freeGuids.includes(o.guid),
        }
      })
    },
    drivers() {
      return (
          _.map(this.getDrivers, (item) => {
            return {
              id: item.id,
              name: formatFio(item),
              license: !item.license.series,
            };
          }) || []
      );
    },
    activeSite: {
      get() {
        return this.form.site;
      },
      set(val) {
        this.form.model = null
        this.form.site = val;
      },
    },
    activeCity: {
      get() {
        return this.getActiveCity;
      },
      set(val) {
        this.form.model = null
        this.changeCity(val);
        if(this.dates.start || this.dates.end) {
          this.loadFreeCars({
            from: this.dates.start,
            to: this.dates.end
          })
        }
      },
    },
  },
  methods: {
    DriverLicenseValidator,
    ...mapActions("popup", ["error"]),
    RequiredValidator,
    ...mapActions("user", ["order"]),
    ...mapActions("drivers", ["loadDrivers"]),
    ...mapActions("config", ["loadCity"]),
    ...mapMutations("config", ["changeCity"]),
    ...mapActions("car", ["loadFreeCars"]),
    onVTreeViewOpened(tabs) {
      if (tabs.length > 1) {
        this.openedDistrict = [tabs.pop()];
      } else {
        this.openedDistrict = tabs;
      }
    },
    formatDate(date) {
      return date ? this.$moment(date).format("DD.MM.YYYY HH:mm") : "";
    },
    async orderNew() {
      this.loading = true;

      this.form.startDate = moment(this.dates.start).toISOString();
      this.form.endDate = moment(this.dates.end).toISOString();
      this.form.days = this.daysCount;

      const areas = this.districts.flatMap((district) => district.areas);

      this.form.route_info = this.selectedDistricts.map(
          (areaId) => areas.find((area) => area.id === areaId).name
      );

      try {
        await this.order(this.form);
        await this.$router.push({name: "ClientRent"});
      } catch (err) {
        await this.error("Ошибка создания заявки.");
        console.log(err);
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.loadCity()

    await this.loadDrivers().then(() => {
      this.loading = false;
    });

    this.districts = await DistrictService.index();

    this.selectedDistricts = this.districts
        .find((district) => district.code === "cfo")
        .areas.map((area) => area.id);

    this.form.name = formatFio(this.user.client);

    if (this.getActiveSite) {
      this.form.site = this.getActiveSite.guid;
    }
    if (this.getCurrentCar) {
      this.form.model = this.getCurrentCar.guid;
    }
    if (this.getDate) {
      this.dates.start = this.getDate.start;
      this.dates.end = this.getDate.end;
    }
  },
  watch: {
    dates(newDates) {
      this.form.model = null
      this.loadFreeCars({
        from: newDates.start,
        to: newDates.end
      })
    }
  }
};
</script>

<style>
.v-treeview .v-treeview-node__children {
  position: absolute !important;
  z-index: 3;
  background: white;
  max-height: 300px;
  overflow: auto;
}

.v-treeview .v-icon--link.v-treeview-node__checkbox {
  color: #fe6500 !important;
  caret-color: #fe6500 !important;
}
</style>
