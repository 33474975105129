import moment from "moment";

export const DriverLicenseValidator = (value,list) => {
  if(!value) return true

  return !list.find(i=>i.id === value).license || 'Для выбора данного водителя необходимо, чтобы были добавлены данные ВУ'
}

export const RequiredValidator = (value) => {
  return value ? true : "Поле должно быть заполнено";
};

export const DateValidator = (value) => {
  return moment(value, "DD.MM.YYYY").isValid() || "Заполнена некорректная дата";
};
